import { PolicyEnum } from 'constants/privacyPolicy';
import { policyMapper } from 'infrastructure/mappers';
import { policyRepository } from 'infrastructure/services';
import { FetchPolicyType } from 'infrastructure/types';
import { renameValidLocale } from 'utils/renameValidLocale';

export const getTermsConditionsAndPrivacyPolicy = async (
  locale = 'en',
  typePolicy: PolicyEnum,
): Promise<any> => {
  const data = await policyRepository.getTermsConditionsAndPrivacyPolicy(
    renameValidLocale(locale),
    typePolicy,
  );
  if (data) {
    return policyMapper(data);
  }
};

export const getTermsConditionsAndPrivacyPolicyStatus = async (
  policyData: FetchPolicyType,
): Promise<boolean> => {
  const result = await policyRepository.checkPolicyStatus(policyData);
  // must be exactly false checking, null\0\undefined is not result from backend
  return result?.status !== false;
};

export const savePolicy = async (
  policyData: FetchPolicyType,
): Promise<string> => {
  const data = await policyRepository.savePolicy(policyData);
  if (data) {
    return data.message;
  }
};
