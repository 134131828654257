import { locale } from 'i18n-js';
import { FetchPolicyType, TermsConditionsAndPrivacyPolicy } from '../types';

export const policyMapper = (
  policyData: TermsConditionsAndPrivacyPolicy,
): FetchPolicyType => ({
  policy_id: policyData.id,
  policy_version: policyData.policy_version,
  language_id: locale,
  text: policyData.text,
});
