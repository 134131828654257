const defaultMessage = 'default';

export const getErrorMessageFromResponseData = (error, fieldsToLookFor) => {
  const errorData = error?.response?.data;

  if (!errorData) return defaultMessage;

  if (fieldsToLookFor && fieldsToLookFor.length) {
    for (const field of fieldsToLookFor) {
      if (errorData[field]) {
        if (typeof errorData[field] === 'string') return errorData[field];

        if (typeof errorData[field] === 'object') {
          const keys = Object.keys(errorData[field]);
          if (keys.length) {
            return errorData[field][keys[0]];
          }
        }
      }
    }
  }

  if (errorData.message) return errorData.message;

  return defaultMessage;
};
