import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useRightPanelStyles = makeStyles(() => ({
  rightPanel: {
    maxWidth: 310,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  panelButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
  },
  hideSmDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideMdUp: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
