import palette from 'theme/palette';
import theme from 'theme/theme';

export const headerWrapperStyles = {
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: palette.common.white,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  logo: {
    width: 100,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: 125,
      marginRight: '40px',
    },
  },
  panel: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  links: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
};
