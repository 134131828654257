import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo from 'public/images/logo.svg';
import logoRound from 'public/images/logoRound.svg';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';

const LinkedLogo = () => {
  const { t } = useTranslate();
  const classes = useStyles();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));

  return (
    <Link href="/">
      <Image
        src={isTablet ? logoRound : logo}
        alt={t('navigation.nployLogo')}
        className={classes.logo}
        title={'nPloy'}
        priority
      />
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  logo: {
    height: 40,
    objectFit: 'scale-down',
    marginRight: theme.spacing(1.8),
    cursor: 'pointer',
    width: '100% !important',
    maxWidth: 125,
    [theme.breakpoints.down(1200)]: {
      height: 70,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(5),
    },
  },
}));

export default LinkedLogo;
