import { fbEvent } from './fbPixel';
import { gtagEvent } from './gtag';
import { linkedinEvent } from './linkedinPixel';

export const eventNames = {
  signIn: 'log_in_web',
  signUp: 'create_account_web',
  createCV: 'create_resume_web',
  buildProfile: 'build_profile_web',
  completedCV: 'complete_resume_web',
  apply: 'apply_web',
  discard: 'discard_web',
  downloadCV: 'download_resume_web',
  enterChatWeb: 'enter_chat_web',
  iAmAStudent: 'i_am_a_student',
};

export const logAnalyticsEvent = (name, data) => {
  gtagEvent({
    action: name,
    category: data?.category,
    label: data?.label,
    value: data?.value,
  });

  fbEvent(name, {
    category: data?.category,
    label: data?.label,
    value: data?.value,
  });

  linkedinEvent(name);
};
