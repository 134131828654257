import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import {
  Box,
  SwipeableDrawer as MuiDrawer,
  IconButton,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from '@nploy/ui/web';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import theme from 'theme/theme';
import { eventNames, logAnalyticsEvent } from 'utils/analytics/event';
import useTranslate from 'utils/i18n/useTranslate';
import {
  openBuildCVModal,
  openCreateAccountModal,
  openLoginModal,
} from 'store/reducers/uiReducer';
import LanguageMenu from './LanguageMenu/LanguageMenu';
import LinkedLogo from './LinkedLogo';
import MoreMenu from './MoreMenu/MoreMenu';
import NavLinks from './NavLinks/NavLinks';

const Drawer = ({ showDrawer, closeDrawer, openDrawer, isAuthed }) => {
  const router = useRouter();
  const { t } = useTranslate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fullComplete: isCVCompleted, partialComplete } = useSelector(
    ({ cv }) => cv,
  );

  const handleOpenCreateAccountModal = () => {
    closeDrawer();
    dispatch(openCreateAccountModal());
  };

  const handleOpenLoginModal = () => {
    closeDrawer();
    dispatch(openLoginModal());
  };

  const handleOpenBuildCVModal = () => {
    closeDrawer();

    logAnalyticsEvent(eventNames.buildProfile);

    dispatch(openBuildCVModal());
  };

  return (
    <MuiDrawer
      anchor={'top'}
      open={showDrawer}
      onClose={closeDrawer}
      onOpen={openDrawer}
      classes={{ root: classes.drawerRoot, paper: classes.drawer }}
      transitionDuration={600}
      id="navigation-menu"
    >
      <Box className={classes.head}>
        <LinkedLogo />

        <IconButton
          onClick={closeDrawer}
          style={{
            width: 50,
            height: 50,
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            color={palette.black.main}
            size={'sm'}
          />
        </IconButton>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.buttons}>
          {isAuthed ? (
            <>
              {/* Authed */}
              {/* Build CV */}
              {router.pathname !== '/resume' && !isCVCompleted && (
                <Button
                  color="secondary"
                  fullWidth
                  onClick={handleOpenBuildCVModal}
                >
                  {partialComplete
                    ? t('navigation.profileIs90')
                    : t('navigation.buildCV')}
                </Button>
              )}
            </>
          ) : (
            <>
              {/* Not authed */}
              {/* Create account */}
              <Button fullWidth mb={1} onClick={handleOpenCreateAccountModal}>
                {t('navigation.createAccount')}
              </Button>

              {/* Login */}
              <Button
                color="paleGrey"
                fullWidth
                mb={1}
                onClick={handleOpenLoginModal}
              >
                {t('navigation.login')}
              </Button>
            </>
          )}
        </Box>

        <NavLinks inDrawer />

        <Box mb={2}>
          <Divider />
        </Box>

        {isAuthed && (
          <>
            <MoreMenu onClose={closeDrawer} />

            <Box mb={2}>
              <Divider />
            </Box>
          </>
        )}

        <LanguageMenu onClose={closeDrawer} />
      </Box>
    </MuiDrawer>
  );
};

const useStyles = makeStyles(() => ({
  drawerRoot: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    height: '100%',
  },
  head: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(3),
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  buttons: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

Drawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool.isRequired,
  isAuthed: PropTypes.bool.isRequired,
};

export default Drawer;
