import React from 'react';
import Image from 'next/image';
import { Box, CircularProgress } from '@mui/material';
import logo from 'public/images/logo.svg';
import { spinnerWithLogoStyles } from './spinner-with-logo.styles';

export const SpinnerWithLogo = () => (
  <Box sx={spinnerWithLogoStyles.container}>
    <CircularProgress
      color="inherit"
      sx={spinnerWithLogoStyles.loader}
      size={56}
      thickness={3}
    />
    <Image src={logo} alt="nPloy Logo" title="nPloy" />
  </Box>
);
