import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@nploy/ui/web';
import useTranslate from 'utils/i18n/useTranslate';
import { closeFeedback } from 'store/reducers/feedbackReducer';

export const SnackbarProvider = () => {
  const { t } = useTranslate();
  const { message, type, open } = useSelector(({ feedback }) => feedback);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeFeedback());
  };

  return (
    <Snackbar
      open={open}
      severity={type}
      handleClose={handleClose}
      message={type && message ? t(`feedback.${type}.${message}`) : ''}
    />
  );
};
