/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonProps, PolicyModalProps } from '@nploy/ui/domain';
import { Button, Modal } from '@nploy/ui/web';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  modal: {},
  modalContent: {},
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  disagreeBtn: {
    backgroundColor: '#b5b9bc',
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  iconButton: {
    height: 40,
    width: 40,
  },
  root: {
    textAlign: 'justify',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-end',
    },
  },
  paper: {
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      maxWidth: '80%',
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95%',
      width: '95%',
    },
  },
}));

export const PolicyModal: FC<PolicyModalProps> = ({
  open,
  handleClose,
  title,
  children,
  onSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslate('common');
  const agreeProps: ButtonProps = {
    onClick: onSubmit,
    mt: 2,
    mb: 2,
    type: 'submit',
  };
  const disagreeProps: ButtonProps = {
    ...agreeProps,
    onClick: handleClose,
    className: classes.disagreeBtn,
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      classes={{ container: classes.root, paper: classes.paper }}
      title={title}
      fixedWidth={false}
      titleComponent="h4"
      className={classes.modal}
      contentContainerClass={classes.modalContent}
    >
      <>
        {children}
        <Box className={classes.btnContainer}>
          <Button {...disagreeProps}>{t('privacyPolicy.disagree')}</Button>
          <Button {...agreeProps}>{t('privacyPolicy.agree')}</Button>
        </Box>
      </>
    </Modal>
  );
};
