import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import theme from 'theme/theme';

const isCurrentRoute = (currentPath, href) => {
  if (!currentPath || !href) return false;
  if (currentPath.length <= 4 && href === '/') return true;
  if (href !== '/' && currentPath.includes(href)) return true;
  return false;
};

const NavLinkItem = ({ title, href, inDrawer }) => {
  const classes = useStyles();
  const router = useRouter();

  if (href.slice(0, 4) === 'http') {
    return (
      <Link href={href} className={classes.link}>
        <Typography
          variant={inDrawer ? 'h1' : 'h3'}
          className={classnames(
            inDrawer ? classes.navLinkInDrawer : classes.navLink,
          )}
        >
          {title}
        </Typography>
      </Link>
    );
  }

  return (
    <Link href={href} passHref className={classes.link}>
      <Typography
        variant={inDrawer ? 'h1' : 'h3'}
        className={classnames(
          classes.link,
          inDrawer ? classes.navLinkInDrawer : classes.navLink,
          isCurrentRoute(router.pathname, href) && classes.active,
        )}
      >
        {title}
      </Typography>
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
  navLink: {
    fontSize: '1.0625rem',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  navLinkInDrawer: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
  },
  active: {
    textDecoration: 'underline',
  },
}));

NavLinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  inDrawer: PropTypes.bool,
};

NavLinkItem.defaultProps = {
  inDrawer: false,
};

export default NavLinkItem;
