import { getApps } from 'firebase/app';
import {
  getMessaging,
  getToken,
  deleteToken,
  isSupported,
} from 'firebase/messaging';
import localforage from 'localforage';
import firebaseApp from 'utils/firebase';
import { sendFCMToken } from 'utils/swr/hooks/useNotifications';

const firebaseCloudMessaging = {
  //checking whether token is available in indexed DB
  tokenInLocalStorage: async () => {
    return localforage.getItem('fcm_token');
  },

  getFcmToken: async () => {
    const messaging = getMessaging(firebaseApp);
    //getting token from FCM

    const fcmToken = await getToken(messaging);

    if (fcmToken) {
      // send token to BE
      sendFCMToken(fcmToken);
      //return the FCM token after saving it
      return fcmToken;
    }
  },

  init: async function () {
    getMessaging(firebaseApp);

    if (getApps()) {
      try {
        const isSupport = await isSupported();
        if (isSupport) {
          //requesting notification permission from browser
          const status = await Notification.requestPermission();

          if (status === 'granted') {
            this.getFcmToken();
          }
        }
      } catch (error) {
        return null;
      }
    } else {
      this.getFcmToken();
    }
  },
  remove: async function () {
    const messaging = getMessaging(firebaseApp);
    return await deleteToken(messaging);
  },
};

export { firebaseCloudMessaging };
