import theme from 'theme/theme';

export const spinnerWithLogoStyles = {
  container: {
    position: 'relative',
    borderRadius: 10,
    padding: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    color: theme.palette.primary.main,
  },
};
