import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import theme from 'theme/theme';
import navLinks from 'constants/navLinks';
import useTranslate from 'utils/i18n/useTranslate';
import NavLinkItem from './NavLinkItem';

const NavLinks = ({ inDrawer }) => {
  const { t } = useTranslate();
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.navLinks, inDrawer && classes.inDrawer)}
      component="nav"
    >
      {navLinks.map(({ title, link }) => (
        <NavLinkItem
          key={link}
          href={link}
          title={t(title)}
          inDrawer={inDrawer}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  navLinks: {
    display: 'flex',
    marginRight: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 490,
    justifyContent: 'space-between',
    flexGrow: 1,
    [theme.breakpoints.down(1100)]: {
      maxWidth: 450,
    },
  },
  inDrawer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

NavLinks.propTypes = {
  inDrawer: PropTypes.bool,
};

NavLinks.defaultProps = {
  inDrawer: false,
};

export default NavLinks;
