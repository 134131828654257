import React, { useState, useRef, useCallback } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import palette from 'theme/palette';
import useTranslate from 'utils/i18n/useTranslate';
import placeholder from '../../../../public/images/icons/ProfilePicPlaceholder.svg';
import MoreMenu from '../MoreMenu/MoreMenu';

const DesktopMoreMenu = ({ image, className, containerClassName }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslate();

  const handleToogle = useCallback(() => {
    setOpen(!open);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <Box className={containerClassName}>
      <ButtonBase
        ref={anchorRef}
        className={classnames(
          className,
          classes.imageCircleButton,
          open && classes.buttonActive,
          !image && classes.placeholderBackground,
        )}
        onClick={handleToogle}
        id="more-menu-button"
        aria-label="more-menu"
      >
        <Box className={classes.imageContainer}>
          {image ? (
            <Image
              src={image}
              alt={t('img.profilePictureAlt')}
              title={t('img.moreMenuTitle')}
              fill
            />
          ) : (
            <Image
              src={placeholder}
              className={classes.placeholder}
              alt={t('img.placeholderAlt')}
              title={t('img.moreMenuTitle')}
              width={25}
              height={25}
            />
          )}
        </Box>
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper classes={{ root: classes.menuContainer }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MoreMenu
                  open={open}
                  onListKeyDown={handleListKeyDown}
                  onClose={handleClose}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  imageCircleButton: {
    height: 48,
    width: 48,
    padding: 2,
    borderRadius: '50%',
    backgroundColor: palette.common.white,
    border: 'solid',
    borderWidth: 2,
    borderColor: palette.common.white,
  },
  buttonActive: {
    borderColor: palette.primary.main,
  },
  placeholderBackground: {
    backgroundColor: palette.secondary.main,
  },
  imageContainer: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  placeholder: {
    width: '50%',
  },
  menuContainer: {
    width: 240,
  },
}));

DesktopMoreMenu.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

DesktopMoreMenu.defaultProps = {
  image: '',
  className: '',
  containerClassName: '',
};

export default DesktopMoreMenu;
