import React, { forwardRef } from 'react';
import { ListItemText, MenuItem, MenuList } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import NotificationItem from '../NotificationItem';
import { INotificationsMenu } from './notifications-menu.interface';
import { notificationsMenuStyles } from './notifications-menu.styles';

export const NotificationMenu = forwardRef<
  HTMLUListElement,
  INotificationsMenu
>(({ open, onListKeyDown, notifications }, ref) => {
  const { t } = useTranslate();

  return (
    <MenuList
      ref={ref}
      autoFocusItem={open}
      onKeyDown={onListKeyDown}
      id="more-menu"
      aria-labelledby="more-menu-button"
      component="ul"
      sx={notificationsMenuStyles.menu}
    >
      {notifications ? (
        notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))
      ) : (
        <MenuItem sx={notificationsMenuStyles.noNotifications}>
          <ListItemText>{t('notifications.noNotifications')}</ListItemText>
        </MenuItem>
      )}
    </MenuList>
  );
});

// Needed to forward refs for click away listener
NotificationMenu.displayName = 'NotificationMenu';

NotificationMenu.defaultProps = {
  onListKeyDown: () => {
    // do nothing
  },
  open: false,
  notifications: null,
};

export default NotificationMenu;
