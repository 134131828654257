import React from 'react';
import { Backdrop } from '@mui/material';
import { SpinnerWithLogo } from 'components/ui';
import { simpleFullScreenLoaderStyles } from './simple-full-screen-loader.styles';

export const SimpleFullScreenLoader = () => (
  <Backdrop sx={simpleFullScreenLoaderStyles.backdrop} open>
    <SpinnerWithLogo />
  </Backdrop>
);
