import React, { useState, useRef, useCallback } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import theme from 'theme/theme';
import flags from 'constants/flags';
import useTranslate from 'utils/i18n/useTranslate';
import LanguageMenu from '../LanguageMenu/LanguageMenu';

const DesktopLanguageMenu = ({ image, className, containerClassName }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t, lang } = useTranslate();

  const handleToogle = useCallback(() => {
    setOpen(!open);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <Box className={containerClassName} position="relative">
      <ButtonBase
        ref={anchorRef}
        className={classnames(
          className,
          classes.imageCircleButton,
          open && classes.buttonActive,
          !image && classes.placeholderBackground,
        )}
        onClick={handleToogle}
        id="language-menu-button"
        aria-label="language-menu"
      >
        <Box className={classes.imageContainer}>
          <Image
            src={flags[lang.toUpperCase()]}
            className={classes.image}
            alt={`${t('img.flagOfAlt')} ` + t(`languages.countries.${lang}`)}
            title={t(`languages.${lang}`)}
          />
        </Box>
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper classes={{ root: classes.menuContainer }}>
              <ClickAwayListener onClickAway={handleClose}>
                <LanguageMenu
                  open={open}
                  onListKeyDown={handleListKeyDown}
                  onClose={handleClose}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  imageCircleButton: {
    height: 35,
    width: 35,
    padding: 1,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    border: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.common.white,
    position: 'relative',
  },
  buttonActive: {
    borderColor: theme.palette.primary.main,
  },
  placeholderBackground: {
    backgroundColor: theme.palette.common.white,
  },
  imageContainer: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '100%',
    objectFit: 'contain',
  },
  menuContainer: {
    width: 240,
    px: 100,
  },
}));

DesktopLanguageMenu.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

DesktopLanguageMenu.defaultProps = {
  image: '',
  className: '',
  containerClassName: '',
};

export default DesktopLanguageMenu;
