/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import { CreateAccountSchema } from '@nploy/shared';
import {
  Button,
  GoogleButton,
  LinkedInButton,
  Checkbox,
  TextField,
} from '@nploy/ui/web';
import { Formik, Form, Field } from 'formik';
import { createAccountFormInitialValues } from 'constants/initial-values';
import useTranslate from 'utils/i18n/useTranslate';
import { useAuth } from 'utils/swr/hooks/useAuth';
import { useAppDispatch } from 'hooks/*';
import {
  closeCreateAccountModal,
  openLoginModal,
  closeLoginModal,
} from 'store/reducers/uiReducer';
import { TermsAndPolicyLabel } from 'components/Policy';
import CustomLink from '../../ui/CustomLink';
import { ICreateAccountForm } from './create-account-form.interface';
import { useCreateAccountFormStyles } from './create-account-form.styles';

export const CreateAccountForm: FC<ICreateAccountForm> = ({ open }) => {
  const { t } = useTranslate();
  const { signUp, signUpWithLinkedIn, signUpWithGoogle } = useAuth();

  const [isGoogleLoading, setGoogleLoading] = useState(false);
  const [isLinkedInLoading, setLinkedInLoading] = useState(false);
  const classes = useCreateAccountFormStyles();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const handleClose = () => {
    dispatch(closeLoginModal());
    if (open) {
      router.replace(router.asPath.split('#')[0]);
    }
  };

  const handleSubmit = async (
    { full_name, email, password }: typeof createAccountFormInitialValues,
    { setSubmitting },
  ) => {
    await signUp({ full_name, email, password });
    setSubmitting(false);
    handleClose();
  };

  const handleGoogleSubmit = async (token: string) => {
    setGoogleLoading(true);
    await signUpWithGoogle(token);
    setGoogleLoading(false);
  };

  const handleLinkedInSubmit = async (code: string) => {
    setLinkedInLoading(true);
    await signUpWithLinkedIn(code);
    setLinkedInLoading(false);
  };

  const handleLogin = () => {
    dispatch(closeCreateAccountModal());
    dispatch(openLoginModal());
  };

  return (
    <Formik
      initialValues={createAccountFormInitialValues}
      validationSchema={CreateAccountSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form>
          {/* Terms and consent */}
          <Box mb={1.5}>
            <Field name="terms" type="checkbox">
              {(props) => (
                <Checkbox
                  disabled={
                    isSubmitting || isGoogleLoading || isLinkedInLoading
                  }
                  {...props}
                  label={<TermsAndPolicyLabel />}
                />
              )}
            </Field>

            <Field name="consent" type="checkbox">
              {(props) => (
                <Checkbox
                  disabled={
                    isSubmitting || isGoogleLoading || isLinkedInLoading
                  }
                  {...props}
                  label={t('createAccount.consent')}
                />
              )}
            </Field>
          </Box>

          <Box pr={[0, 1]} pl={[0, 1]} mb={4} position="relative">
            {/* Overlay to disable form when consents are not given */}
            {(!values.terms || !values.consent) && (
              <Box className={classes.disableOverlay} />
            )}

            {/* Social buttons */}
            <GoogleButton
              fullWidth
              mb={2}
              handleGoogleSubmit={handleGoogleSubmit}
              loading={isGoogleLoading}
              disabled={isSubmitting || isLinkedInLoading}
            >
              {t('createAccount.signUpGoogle')}
            </GoogleButton>

            <LinkedInButton
              fullWidth
              mb={4}
              handleLinkedInSubmit={handleLinkedInSubmit}
              loading={isLinkedInLoading}
              disabled={isSubmitting || isGoogleLoading}
            >
              {t('createAccount.signUpLinkedIn')}
            </LinkedInButton>

            {/* Or */}
            <Box textAlign="center" mb={3}>
              <Typography variant="body2" color="textSecondary">
                - {t('createAccount.or').toUpperCase()} -
              </Typography>
            </Box>

            <Box mb={1}>
              {/* Full name */}
              <Field name="full_name">
                {(props) => (
                  <TextField
                    disabled={
                      isSubmitting || isGoogleLoading || isLinkedInLoading
                    }
                    type="text"
                    label={t('createAccount.full_name')}
                    fullWidth
                    {...props}
                  />
                )}
              </Field>

              {/* Email */}
              <Field name="email">
                {(props) => (
                  <TextField
                    disabled={
                      isSubmitting || isGoogleLoading || isLinkedInLoading
                    }
                    type="email"
                    label={t('createAccount.email')}
                    fullWidth
                    {...props}
                  />
                )}
              </Field>

              {/* Password */}
              <Field name="password">
                {(props) => (
                  <TextField
                    disabled={
                      isSubmitting || isGoogleLoading || isLinkedInLoading
                    }
                    type="password"
                    label={t('createAccount.password')}
                    fullWidth
                    withSecureTextEntry
                    {...props}
                  />
                )}
              </Field>
            </Box>

            {/* Submit button */}
            <Button
              fullWidth
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || isGoogleLoading || isLinkedInLoading}
            >
              {t('createAccount.title')}
            </Button>
          </Box>

          {/* Log in link */}
          <Box textAlign="center">
            <Typography variant="body2" component="span">
              {t('createAccount.alreadyHaveAccount')}{' '}
            </Typography>
            <CustomLink onClick={handleLogin}>
              {t('createAccount.logIn')}
            </CustomLink>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
