import { mutate } from 'swr';
import { axios, uploadBase64 } from '@nploy/shared';
import { openFeedback } from '../reducers/feedbackReducer';
import { SET_CV_DATA, SET_INITIAL_CV_COMPLETE } from './types';

export const setCVData = (data) => (dispatch) => {
  if (data) {
    return dispatch({
      type: SET_CV_DATA,
      payload: { data },
    });
  }
};

export const uploadCV = (file, callback) => async (dispatch) => {
  try {
    const res = await uploadBase64(file, 'resume/import/upload');

    if (res) {
      dispatch(openFeedback({ type: 'success', message: 'cvUploaded' }));

      mutate('resume');

      if (callback) callback();
    }
  } catch (error) {
    dispatch(openFeedback({ type: 'error', message: error.message }));
  }
};

export const downloadCV = async (locale) => {
  const { data } = await axios.get('resume/get-pdf-resume', {
    params: {
      locale,
    },
  });

  return data;
};

export const completeInitialCV = (callback) => async (dispatch) => {
  try {
    await axios.post('resume/initial-complete-update');

    if (callback) callback();
  } catch (error) {
    dispatch(openFeedback({ type: 'error', message: error.message }));
  }
};

export const setInitialCVComplete = (dispatch) =>
  dispatch({ type: SET_INITIAL_CV_COMPLETE });
