import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import { IHeaderIconButton } from './header-icon-button.interface';
import { headerIconButtonStyles } from './header-icon-button.styles';

export const HeaderIconButton = React.forwardRef<any, IHeaderIconButton>(
  ({ icon, iconColor, itemsNumber, className, href, onClick }, ref) => (
    <IconButton
      className={className}
      ref={ref}
      href={href}
      onClick={onClick}
      sx={headerIconButtonStyles.iconButton}
    >
      <FontAwesomeIcon
        icon={icon}
        color={iconColor}
        style={headerIconButtonStyles.iconStyle}
      />
      {!!itemsNumber && (
        <Box sx={headerIconButtonStyles.unreadContainer}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={headerIconButtonStyles.unreadText}
            component="span"
          >
            {itemsNumber <= 9 ? itemsNumber : '9+'}
          </Typography>
        </Box>
      )}
    </IconButton>
  ),
);

HeaderIconButton.defaultProps = {
  iconColor: palette.darkGrey.main,
  itemsNumber: 0,
  className: '',
  href: '',
  onClick: null,
};

HeaderIconButton.displayName = 'HeaderIconButton';

export default HeaderIconButton;
