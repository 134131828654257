import React, { FC } from 'react';
import useTranslate from 'utils/i18n/useTranslate';
import CustomLink from 'components/ui/CustomLink';

export const TermsAndPolicyLabel: FC = () => {
  const { t } = useTranslate();
  return (
    <>
      {t('createAccount.iHaveReadAndAgree')}
      <CustomLink href="term-and-conditions" target="_blank" external>
        {t('createAccount.terms')}
      </CustomLink>
      {t('createAccount.and')}
      <CustomLink href="privacy-policy" target="_blank" external>
        {t('createAccount.policy')}
      </CustomLink>
      {t('createAccount.ofNploy')}
    </>
  );
};
