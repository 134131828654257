import palette from 'theme/palette';

export const deepLinksModalStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 1,
    paddingLeft: 1,
  },
  text: {
    fontSize: '14px',
    flex: 1,
    marginLeft: '20px',
    marginRight: '20px',
  },
  button: {
    minWidth: 100,
    borderRadius: 1,
    backgroundColor: palette.primary.main,
    '& a': {
      display: 'inline-block',
      width: '100%',
      height: '100%',
      textDecoration: 'none !important',
      color: palette.black.main,
    },
  },
  image: {
    width: '100%',
    maxWidth: 48,
    backgroundColor: palette.paleGrey.main,
    borderRadius: 10,
  },
  closeButton: {
    backgroundColor: palette.darkGrey.main,
  },
};
