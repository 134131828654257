import React, { useMemo, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faThumbsUp, faUserCheck } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsUp as faThumbsUpSolid,
  faUserCheck as faUserCheckSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';

const NotificationItem = ({ notification: { type, body, dialog_id, url } }) => {
  const { t } = useTranslate();
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const getIcon = useMemo(() => {
    if (isHovered) {
      if (type === 'match_request') return faUserCheckSolid;
      return faThumbsUpSolid;
    }
    if (type === 'match_request') return faUserCheck;
    return faThumbsUp;
  }, [isHovered]);

  return (
    <Link
      href={
        url
          ? url.split('/en')[1]
          : dialog_id
          ? `/messages?chat=${dialog_id}`
          : ''
      }
      passHref
      prefetch={false}
      className={classes.link}
    >
      <MenuItem onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        <ListItemIcon classes={{ root: classes.icon }}>
          <FontAwesomeIcon
            icon={getIcon}
            color={isHovered ? palette.secondary.main : palette.common.black}
          />
        </ListItemIcon>

        <ListItemText classes={{ primary: classes.itemText }}>
          {t(`notifications.${type}.title`)}
          <Typography variant="body1" className={classes.secondaryText}>
            {t(`notifications.${type}.prefix`)}{' '}
            <Typography component="span" className={classes.notificationBody}>
              {body}
            </Typography>
          </Typography>
        </ListItemText>
      </MenuItem>
    </Link>
  );
};

const useStyles = makeStyles(() => ({
  item: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: palette.paleGrey.main,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  itemText: {
    fontWeight: 400,
    margin: 0,
    fontSize: '1rem',
  },
  icon: {
    minWidth: 30,
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  secondaryText: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  notificationBody: {
    fontWeight: 600,
    fontSize: 'inherit',
    whiteSpace: 'normal',
    lineBreak: 'anywhere',
  },
  link: {
    color: 'inherit',
  },
}));

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.string,
    dialog_id: PropTypes.number,
    url: PropTypes.string,
  }).isRequired,
};

export default NotificationItem;
