import { useDispatch } from 'react-redux';
import { captureEvent } from '@sentry/nextjs';
import useSWR from 'swr';
import { axios, deleteAuthToken } from '@nploy/shared';
import { openFeedback } from 'store/reducers/feedbackReducer';
import isWindow from '../../isWindow';
import { fetchData } from '../helpers/fetchData';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';
import { useAuth } from './useAuth';
import { mutateCV } from './useCV';

const DEFAULT_ERROR = 'Network Error';

const fetchUser = async () => {
  if (!shouldFetchIfHasToken()) return false;
  try {
    const data = await fetchData('user');
    return data;
  } catch (error) {
    if (error.response.status === 403) {
      deleteAuthToken();
    }
    throw error;
  }
};

export const useUser = () => {
  const dispatch = useDispatch();
  const { signOut } = useAuth();

  const { data, error, mutate } = useSWR('user', fetchUser, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Never retry on 403.
      if (error.status === 403) {
        signOut();
        return false;
      }
      // Only retry up to 3 times. Then delete token
      if (retryCount >= 3) {
        deleteAuthToken();
      }
      // Retry after 7 seconds.
      setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 7000);
    },
  });

  const deletePhoto = async (callback) => {
    try {
      await axios.delete('resume/personal-information/photo');
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(openFeedback({ type: 'error', message: error.message }));
      throw error;
    }
  };

  const uploadPhoto = async (file) => {
    try {
      const data = new FormData();
      data.append('image', file);
      await axios.post('resume/personal-information/photo/upload', data);
    } catch (error) {
      dispatch(openFeedback({ type: 'error', message: error.message }));
      throw error;
    }
  };

  const savePersonalInfo = async (personalInfo) => {
    try {
      await axios.post('resume/personal-information/store', {
        full_name: personalInfo.full_name,
        phone_number: personalInfo.phone_number,
        city_id: personalInfo.city.id,
      });
      await mutate();
      mutateCV();
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message:
            error.response.data.errors?.phone_number?.[0] || DEFAULT_ERROR,
        }),
      );
      throw error;
    }
  };

  const changeEmail = async (email) => {
    await mutate({ ...data, email }, false);
    try {
      const res = await axios.post('auth/change-email', {
        email,
      });
      dispatch(openFeedback({ type: 'success', message: res.data.message }));
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: error.response.data.errors?.email?.[0] || DEFAULT_ERROR,
        }),
      );
      throw error;
    }
  };

  const deleteAccount = async () => {
    try {
      await axios.post('delete-account');
      dispatch(openFeedback({ type: 'success', message: 'userDeleted' }));
      signOut();
    } catch (error) {
      dispatch(
        openFeedback({ type: 'error', message: error.response.data.message }),
      );
      throw error;
    }
  };

  const changePassword = async (params) => {
    try {
      await axios.post('auth/change-password', params);
      dispatch(openFeedback({ type: 'success', message: 'passwordChanged' }));
    } catch (error) {
      dispatch(
        openFeedback({ type: 'error', message: error.response.data.message }),
      );
      throw error;
    }
  };

  const setUserStudentStatus = async () => {
    try {
      await axios.post('settings/set-student-status');
    } catch (error) {
      captureEvent(error);
    }
  };

  return {
    user: {
      full_name: data?.full_name,
      email: data?.email,
      phone_number: data?.phone_number,
      city: data?.city,
      photo_url: data?.photo_url,
      isAuthed: !!data,
    },
    loading:
      isWindow() &&
      shouldFetchIfHasToken() &&
      typeof data === 'undefined' &&
      !error,
    error,
    savePersonalInfo,
    changeEmail,
    uploadPhoto,
    deletePhoto,
    deleteAccount,
    changePassword,
    setUserStudentStatus,
  };
};
