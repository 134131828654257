/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonProps, DisagreeModalProps } from '@nploy/ui/domain';
import { Button, Modal } from '@nploy/ui/web';
import theme from 'theme/theme';

const useStyles = makeStyles(() => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  cancelBtn: {
    backgroundColor: '#b5b9bc',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  root: {
    textAlign: 'justify',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-center',
    },
  },
  paper: {
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      maxWidth: '80%',
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95%',
      width: '95%',
    },
  },
}));

export const DisagreeModal: FC<DisagreeModalProps> = ({
  title,
  open,
  cancelButtonOnCLicked,
  okButtonOnCLicked,
  cancelButtonText,
  okButtonText,
}) => {
  const classes = useStyles();

  const agreeProps: ButtonProps = {
    onClick: okButtonOnCLicked,
    mt: 2,
    mb: 2,
    type: 'submit',
  };
  const disagreeProps: ButtonProps = {
    ...agreeProps,
    onClick: cancelButtonOnCLicked,
    className: classes.cancelBtn,
  };
  return (
    <Modal
      open={open}
      onClose={cancelButtonOnCLicked}
      aria-labelledby=""
      classes={{ container: classes.root, paper: classes.paper }}
      title=""
      fixedWidth={false}
      titleComponent="h4"
      contentContainerClass=""
      className=""
    >
      <>
        <Typography variant="h4" component="h4" className={classes.content}>
          {title}
        </Typography>
        <Box className={classes.btnContainer}>
          <Button {...disagreeProps}>{cancelButtonText}</Button>
          <Button {...agreeProps}>{okButtonText}</Button>
        </Box>
      </>
    </Modal>
  );
};
