/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import { LoginSchema } from '@nploy/shared';
import { Button, GoogleButton, LinkedInButton, TextField } from '@nploy/ui/web';
import { Formik, Form, Field } from 'formik';
import useTranslate from 'utils/i18n/useTranslate';
import { useAuth } from 'utils/swr/hooks/useAuth';
import { useAppDispatch } from 'hooks/*';
import {
  closeLoginModal,
  openCreateAccountModal,
  openForgotPasswordModal,
} from 'store/reducers/uiReducer';
import CustomLink from '../../ui/CustomLink';
import { ILoginForm } from './login-form.interface';

export const LoginForm: FC<ILoginForm> = ({ open }) => {
  const { t } = useTranslate();
  const [isLinkedInLoading, setLinkedInLoading] = useState(false);
  const [isGoogleLoading, setGoogleLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { signIn, signInWithLinkedIn, signInWithGoogle } = useAuth();
  const router = useRouter();

  const handleClose = () => {
    dispatch(closeLoginModal());
    if (open) {
      router.replace(router.asPath.split('#')[0]);
    }
  };

  const handleSubmit = async ({ email, password }, { setSubmitting }) => {
    await signIn({ email, password });
    setSubmitting(false);
    handleClose();
  };

  const handleLinkedInSubmit = async (code: number) => {
    setLinkedInLoading(true);
    await signInWithLinkedIn(code);

    setLinkedInLoading(false);
  };

  const handleGoogleSubmit = async (token: string) => {
    setGoogleLoading(true);
    await signInWithGoogle(token);
    setGoogleLoading(false);
  };

  const handleCreate = () => {
    dispatch(closeLoginModal());
    dispatch(openCreateAccountModal());
  };

  const handleForgotPassword = () => {
    dispatch(closeLoginModal());
    dispatch(openForgotPasswordModal());
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box pr={[0, 1]} pl={[0, 1]} mb={4} position="relative">
            {/* Social buttons */}
            <GoogleButton
              fullWidth
              mb={2}
              handleGoogleSubmit={handleGoogleSubmit}
              loading={isGoogleLoading}
              disabled={isSubmitting || isLinkedInLoading}
            >
              {t('login.signInGoogle')}
            </GoogleButton>

            <LinkedInButton
              fullWidth
              mb={4}
              handleLinkedInSubmit={handleLinkedInSubmit}
              loading={isLinkedInLoading}
              disabled={isSubmitting || isGoogleLoading}
            >
              {t('login.signInLinkedIn')}
            </LinkedInButton>

            {/* Or */}
            <Box textAlign="center" mb={3}>
              <Typography variant="body2" color="textSecondary">
                - {t('login.or').toUpperCase()} -
              </Typography>
            </Box>

            <Box mb={1}>
              {/* Email */}
              <Field name="email">
                {(props) => (
                  <TextField
                    disabled={
                      isSubmitting || isGoogleLoading || isLinkedInLoading
                    }
                    type="email"
                    label={t('login.email')}
                    fullWidth
                    {...props}
                  />
                )}
              </Field>

              {/* Password */}
              <Field name="password">
                {(props) => (
                  <TextField
                    disabled={
                      isSubmitting || isGoogleLoading || isLinkedInLoading
                    }
                    type="password"
                    label={t('login.password')}
                    fullWidth
                    withSecureTextEntry
                    {...props}
                  />
                )}
              </Field>
            </Box>

            {/* Submit button */}
            <Button
              fullWidth
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || isGoogleLoading || isLinkedInLoading}
            >
              {t('login.title')}
            </Button>
          </Box>

          {/* Forgotten password */}
          <Box textAlign="center" mb={2}>
            <CustomLink onClick={handleForgotPassword}>
              {t('login.forgottenPassword')}
            </CustomLink>
          </Box>

          {/* Don’t have an account */}
          <Box textAlign="center">
            <Typography variant="body2" component="span">
              {t('login.dontHaveAnAccount')}{' '}
            </Typography>
            <CustomLink onClick={handleCreate}>{t('login.create')}</CustomLink>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
