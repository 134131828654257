import { userAgentRules } from 'constants/userAgentRulesRegexp';
import { operatingSystemRules } from 'constants/operatingSystemRulesRegexp';

const REQUIRED_VERSION_PARTS = 3;

export function detect() {
  if (typeof navigator !== 'undefined') {
    return parseUserAgent(navigator.userAgent);
  }
}

function matchUserAgent(ua) {
  return (
    ua !== '' &&
    userAgentRules.reduce((matched, [browser, regex]) => {
      if (matched) {
        return matched;
      }

      const uaMatch = regex.exec(ua);
      return !!uaMatch && [browser, uaMatch];
    }, false)
  );
}

function createVersionParts(count) {
  const output = [];
  for (let ii = 0; ii < count; ii++) {
    output.push('0');
  }

  return output;
}

export function parseUserAgent(ua) {
  const matchedRule = matchUserAgent(ua);
  if (!matchedRule) {
    return null;
  }

  const [name, match] = matchedRule;

  let versionParts =
    match[1] && match[1].split('.').join('_').split('_').slice(0, 3);
  if (versionParts) {
    if (versionParts.length < REQUIRED_VERSION_PARTS) {
      versionParts = [
        ...versionParts,
        ...createVersionParts(REQUIRED_VERSION_PARTS - versionParts.length),
      ];
    }
  } else {
    versionParts = [];
  }
  const version = versionParts.join('.');
  const os = detectOS(ua);
  return { name, os, version };
}

export function detectOS(ua) {
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const [os, regex] = operatingSystemRules[ii];
    const match = regex.exec(ua);
    if (match) {
      return os;
    }
  }

  return null;
}
