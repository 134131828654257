import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { POLICY, PolicyEnum } from 'constants/privacyPolicy';
import { termsConditionsAndPrivacyPolicyUseCase } from 'useCases';
import { FetchPolicyType } from 'infrastructure/types';
import { createMarkup } from 'utils/createMarkup';
import useTranslate from 'utils/i18n/useTranslate';
import { useAuth } from 'utils/swr/hooks/useAuth';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { PolicyModal } from 'components/Policy/PolicyModal';
import { DisagreeModal } from 'components/ui/DisagreeModal';

const PolicyPopup = (): JSX.Element => {
  const { t, lang } = useTranslate('common');
  const { signOut } = useAuth();
  const router = useRouter();
  const [policyData, setPolicyData] = useState<FetchPolicyType>(null);
  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  const dispatch = useDispatch();
  const [activePolicy, setActivePolicy] = useState(POLICY[0]);
  const [showDisagreeModal, setShowDisagreePopup] = useState(false);

  const acceptDisagree = async () => {
    signOut();
    await router.push('/');
  };

  const dontAcceptDisagree = () => {
    setShowDisagreePopup(false);
    setShowPolicyPopup(true);
  };

  const handleClose = async () => {
    setShowPolicyPopup(false);
    setShowDisagreePopup(true);
  };

  const getPolicyConditions = async (policyType: PolicyEnum) => {
    const itemData =
      await termsConditionsAndPrivacyPolicyUseCase.getTermsConditionsAndPrivacyPolicy(
        lang,
        policyType,
      );
    if (itemData) {
      setPolicyData(itemData);
    }
  };

  const checkPolicyStatus = async (policy: FetchPolicyType) => {
    const policyIndex = POLICY.indexOf(activePolicy);

    const status =
      await termsConditionsAndPrivacyPolicyUseCase.getTermsConditionsAndPrivacyPolicyStatus(
        policy,
      );

    if (!status) {
      setShowPolicyPopup(true);
    } else {
      const nextIndex = policyIndex + 1;

      if (nextIndex < POLICY.length) {
        setActivePolicy(POLICY[nextIndex]);
        await getPolicyConditions(POLICY[nextIndex]);
      }
    }
  };

  const handleAgreePolicy = async () => {
    try {
      await termsConditionsAndPrivacyPolicyUseCase.savePolicy(policyData);
      const policyIndex = POLICY.indexOf(activePolicy);
      const nextIndex = policyIndex + 1;
      setShowPolicyPopup(false);
      setPolicyData(null);
      if (nextIndex < POLICY.length) {
        setActivePolicy(POLICY[nextIndex]);
        await getPolicyConditions(POLICY[nextIndex]);
      }
    } catch (e) {
      dispatch(
        openFeedback({
          type: 'error',
          message: 'Request failed with status code 404',
        }),
      );
    }
  };
  useEffect(() => {
    if (policyData) {
      checkPolicyStatus(policyData);
    }
  }, [policyData]);

  useEffect(() => {
    getPolicyConditions(activePolicy);
  }, []);

  return (
    <>
      <PolicyModal
        open={showPolicyPopup}
        handleClose={handleClose}
        title={t(`privacyPolicy.title.${activePolicy}`)}
        onSubmit={handleAgreePolicy}
      >
        <div dangerouslySetInnerHTML={createMarkup(policyData?.text)} />
      </PolicyModal>
      <DisagreeModal
        open={showDisagreeModal}
        title={t('privacyPolicy.disagreeInformation')}
        okButtonOnCLicked={acceptDisagree}
        cancelButtonOnCLicked={dontAcceptDisagree}
        cancelButtonText={t('back')}
        okButtonText={t('logout')}
      />
    </>
  );
};
export default PolicyPopup;
