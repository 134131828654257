import React, { FC } from 'react';
import { Box, Modal } from '@mui/material';
import { SpinnerWithLogo } from '../spinner-with-logo';
import { ITransparentFullScreenLoader } from './transparent-full-screen-loader.interface';

export const TransparentFullScreenLoader: FC<ITransparentFullScreenLoader> = ({
  open = false,
}) => (
  <Modal
    keepMounted
    disableAutoFocus
    disableEnforceFocus
    disableEscapeKeyDown
    open={open}
  >
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerWithLogo />
    </Box>
  </Modal>
);
