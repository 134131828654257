import { axiosForBuild } from '@nploy/shared';
import { ICandidateCountryDto } from '@nploy/ui-infrastructure';
import {
  IGeneralInfoDto,
  IJobOccupationDto,
  IJobRoleDto,
} from '@nploy/ui-infrastructure-recruiters';

export const fetchBuildData = async <T = any>(
  url: string,
  params?: Record<string, any>,
) => {
  const { data } = await axiosForBuild.get<T>(url, params && { params });
  return data;
};

export const getAllJobsWithFilters = async (params: Record<string, any>) => {
  const { data } = await fetchBuildData('jobs', {
    page: 1,
    per_page: 10000,
    ...params,
  });
  return data;
};

export const getJobsBySlugs = async (params: Record<string, any>) => {
  const { data } = await fetchBuildData('jobsBySlugs', {
    page: 1,
    per_page: 10000,
    ...params,
  });

  return data;
};

export const getJobCategories = async (
  locale: string,
): Promise<IJobOccupationDto[]> =>
  fetchBuildData<IJobOccupationDto[]>('list/job-occupations', {
    all: 1,
    locale,
  });

export const getCities = async () => {
  const data = fetchBuildData('list/cities', {
    all_city: 1,
  });

  return data;
};

export const getLanguagesList = async () => {
  const data = await fetchBuildData('list/languages', {
    all: 1,
  });

  return data;
};

export const getFilters = async (): Promise<IGeneralInfoDto> =>
  fetchBuildData<IGeneralInfoDto>('list/general-info');

export const getFiltersWorkModel = async () =>
  fetchBuildData('list/work-models');

export const getTopCompanies = async () => {
  const data = await fetchBuildData('list/companies', { all: 1 });

  return data;
};

export const getAllCompanies = async () => {
  const data = await fetchBuildData('all-companies');
  return data;
};

export const fetchPrivacyPolicy = async (locale: string) =>
  fetchBuildData(`get-policy/${locale}/type/privacy_policy`);

export const fetchTermsAndConditions = async (locale: string) =>
  fetchBuildData(`get-policy/${locale}/type/t-and-c`);

export const getLanguagesLevel = async () =>
  fetchBuildData('list/language-levels');

export const getJobRoles = async (
  params: Record<string, any>,
): Promise<IJobRoleDto> => fetchBuildData('list/job-roles', params);

export const getAllCountries = async (): Promise<ICandidateCountryDto[]> =>
  fetchBuildData<ICandidateCountryDto[]>('list/countries?all=1');
