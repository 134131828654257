import useSWR from 'swr';
import { axios } from '@nploy/shared';
import { fetchData } from '../helpers/fetchData';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';

export const sendFCMToken = async (fcm_token) => {
  try {
    await axios.post('auth/fcm-web/update', { fcm_token });
  } catch (error) {
    return;
  }
};

export const useNotifications = () => {
  const { data, error, mutate } = useSWR(
    shouldFetchIfHasToken() ? 'notifications' : null,
    fetchData,
    {
      shouldRetryOnError: false,
    },
  );

  const readNotifications = async () => {
    try {
      if (data) {
        await axios.post('notifications/update', {
          notifications: data.map(({ id }) => id),
        });
        mutate();
      }
    } catch (error) {
      return;
    }
  };

  return {
    notifications: data?.slice().reverse(),
    mutate,
    readNotifications,
    error,
  };
};
