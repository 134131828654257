import { captureException } from '@sentry/nextjs';

export enum SessionStorageKeys {
  hideDeepLinkPopup = 'nploy_hide_deep_link_popup',
}

export const getFromSessionStorage = (key: SessionStorageKeys) => {
  if (typeof window !== 'undefined') {
    if (
      window.sessionStorage.getItem(key) &&
      window.sessionStorage.getItem(key) !== 'undefined'
    ) {
      try {
        return JSON.parse(window.sessionStorage.getItem(key));
      } catch (error) {
        captureException(error);
      }
    }
  }
  return null;
};

export const setToSessionStorage = (key: SessionStorageKeys, value: any) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};
