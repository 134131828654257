import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useBuildCvModalStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 700,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
  },
  fileInput: {
    display: 'none',
  },
  importButton: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: theme.spacing(1.5),
    },
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
}));
