import slugifyFunc from "slugify";

function slugify(text, lower = true) {
  if (!text) return "";

  const fixedDashes = text.replace(/_/g, "-");
  const fixedTrailingSpecialChars = fixedDashes.replace(/[.?,;:/]*$/g, "");

  const slug = slugifyFunc(fixedTrailingSpecialChars, { lower });
  return slug;
}

export default slugify;
