export const renameValidLocale = (locale: string): string => {
  switch (locale) {
    case 'bg': {
      return 'bg';
    }
    default: {
      return 'en';
    }
  }
};
