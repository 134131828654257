export const localStorageKeys = {
  onboardingStage: 'nploy_onboarding_stage',
  jobCategories: 'nploy_job_categories',
  country_id: 'nploy_country',
  salary_min: 'nploy_salary_min',
  salary_max: 'nploy_salary_max',
  keywords: 'nploy_keywords',
  employment_levels: 'nploy_employment_levels',
  employment_types: 'nploy_employment_types',
  experience_years_min: 'nploy_experience_years_min',
  experience_years_max: 'nploy_experience_years_max',
  cities: 'nploy_cities',
  lang: 'nploy_lang',
  likedIds: 'nploy_liked_ids',
  dislikedIds: 'nploy_disliked_ids',
  externalDislikedIds: 'external_disliked_ids',
  externalLikedIds: 'external_liked_ids',
  work_models: 'work_models',
};

const persistInStorage = [localStorageKeys.lang];

export const getFromLocalStorage = (key) => {
  if (typeof window !== 'undefined') {
    if (
      window.localStorage.getItem(key) &&
      window.localStorage.getItem(key) !== 'undefined'
    ) {
      return JSON.parse(window.localStorage.getItem(key));
    }
  }
  return null;
};

export const setToLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalStorage = () => {
  if (typeof window !== 'undefined') {
    for (const key in localStorageKeys) {
      if (!persistInStorage.includes(localStorageKeys[key])) {
        window.localStorage.removeItem(localStorageKeys[key]);
      }
    }
  }
};
