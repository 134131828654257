import customScrollbar from 'theme/customScroll';
import theme from 'theme/theme';

export const notificationsMenuStyles = {
  menu: {
    maxHeight: 400,
    maxWidth: '90vw',
    ...customScrollbar,
  },
  noNotifications: {
    '&:hover': {
      cursor: 'auto',
      backGroundColor: theme.palette.common.white,
    },
  },
};
