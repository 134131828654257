/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, FC } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Box, ButtonProps } from '@mui/material';
import { Button } from '@nploy/ui/web';
import { mobileDeepLinksRoutes } from 'constants/mobileDeepLinkRoutes';
import { detect } from 'utils/detectBrowserName';
import { detectBrowserName } from 'utils/helpers/detectBrowser';
import useTranslate from 'utils/i18n/useTranslate';
import { SessionStorageKeys, setToSessionStorage } from 'utils/sessionStorage';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { closeDeepLinksModal } from 'store/reducers/uiReducer';
import ModalWithDeepLinks from 'components/ui/ModalWithDeepLinks';
import { deepLinksModalStyles } from './deep-links-modal.styles';

export const DeepLinksModal: FC = () => {
  const router = useRouter();
  const currentPath = router.asPath;
  const browser = detect();
  const { t } = useTranslate();
  const { jobDeepLink } = useAppSelector(({ jobs }) => jobs);

  const showModal = useAppSelector(({ ui }) => ui.showDeepLinksModal);
  const dispatch = useAppDispatch();
  const [browserName, setBrowserName] = useState(null);
  const [browserImg, setBrowserImg] = useState(null);

  let pathDeepLink = mobileDeepLinksRoutes?.[currentPath] || currentPath;

  if (currentPath.includes('#jobDetails') && jobDeepLink) {
    pathDeepLink = jobDeepLink;
  }

  const mobilePackage =
    browser.os === 'iOS'
      ? process.env.NEXT_PUBLIC_MOBILE_APP_APPSTORE_ID
      : process.env.NEXT_PUBLIC_MOBILE_APP_ANDROID_PACKAGE;

  const deepLinkPrefix =
    browser.os === 'iOS'
      ? `${process.env.NEXT_PUBLIC_MOBILE_APP_SCHEMA}`
      : 'intent';

  const deepLink =
    `${deepLinkPrefix}://${process.env.NEXT_PUBLIC_MOBILE_APP_SCHEMA}/${pathDeepLink}#Intent;scheme=${process.env.NEXT_PUBLIC_MOBILE_APP_SCHEMA};package=${mobilePackage};end` as (
      | string
      | Location
    ) &
      Location;

  const goToApp = () => {
    setTimeout(() => {
      if (browser.os === 'iOS') {
        window.location = process.env.NEXT_PUBLIC_IOS_DOWNLOAD_APP_URL as (
          | string
          | Location
        ) &
          Location;
      }
      // This is a fallback if the app is not installed.
      // It could direct to an app store or a website
      // telling user how to get the app
    }, 95);
    window.location = deepLink;
  };

  const handleClose = () => {
    setToSessionStorage(SessionStorageKeys.hideDeepLinkPopup, true);
    dispatch(closeDeepLinksModal());
  };

  const openButtonProps = {
    sx: deepLinksModalStyles.button,
    size: 'small',
    mt: 2,
    mb: 2,
    onClick: goToApp,
  };
  const continueButtonProps = {
    sx: deepLinksModalStyles.button,
    onClick: handleClose,
    size: 'small',
    mt: 2,
    mb: 2,
  };

  useEffect(() => {
    const detectedBrowser = detectBrowserName(browser);
    setBrowserName(detectedBrowser?.name || '');
    setBrowserImg(detectedBrowser?.img || '');
  }, []);

  return (
    <ModalWithDeepLinks
      open={showModal}
      title={t('deepLinksPopup.title')}
      fixedWidth
      keepMounted={false}
      onClose={undefined}
      titleComponent={undefined}
    >
      <Box sx={deepLinksModalStyles.container}>
        <Image
          height="48"
          width="48"
          src="/images/logoRound.png"
          style={deepLinksModalStyles.image}
          alt="nPloy logo"
        />
        <Box sx={deepLinksModalStyles.text}>{t('app')}</Box>
        <Button {...(openButtonProps as ButtonProps)}>{t('open')}</Button>
      </Box>
      <Box sx={deepLinksModalStyles.container}>
        <Image
          height="48"
          width="48"
          src={browserImg}
          style={deepLinksModalStyles.image}
          alt={`Browser ${browserName} icon`}
        />
        <Box sx={deepLinksModalStyles.text}>
          {browserName || t('openInBrowser')}
        </Box>
        <Button {...(continueButtonProps as ButtonProps)}>
          {t('continue')}
        </Button>
      </Box>
    </ModalWithDeepLinks>
  );
};
