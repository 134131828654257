import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import palette from 'theme/palette';
import theme from 'theme/theme';
import flags from 'constants/flags';
import { setClientLanguage } from 'utils/i18n/clientLanguage';
import useTranslate from 'utils/i18n/useTranslate';
import i18nConfig from '../../../../i18n.json';

const LanguageMenuItem = ({ lang, onClick }) => {
  const { t, lang: currentLang } = useTranslate();
  const classes = useStyles();

  const handleSelect = () => {
    onClick(lang);
  };

  return (
    <MenuItem className={classes.item} onClick={handleSelect}>
      <ListItemIcon classes={{ root: classes.icon }}>
        <Image
          src={flags[lang.toUpperCase()]}
          className={classes.image}
          alt={
            `${t('img.flagOfAlt')} ` +
            t(`languages.countries.${lang?.toUpperCase()}`)
          }
          title={t(`languages.${lang}`)}
        />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.itemText }}
        className={classnames(lang === currentLang && classes.isSelected)}
      >
        {t(`languages.${lang}`)}
      </ListItemText>
    </MenuItem>
  );
};

const LanguageMenu = React.forwardRef(
  ({ open, onListKeyDown, onClose }, ref) => {
    const router = useRouter();

    const { locales } = i18nConfig;

    const handleSelectLanguage = (selectedLang) => {
      if (selectedLang === router.locale) return;

      setClientLanguage(selectedLang);

      const currentPath = router.asPath;

      router.replace(currentPath, currentPath, { locale: selectedLang });
      onClose();
    };

    return (
      <MenuList
        ref={ref}
        autoFocusItem={open}
        onKeyDown={onListKeyDown}
        id="language-menu"
        aria-labelledby="language-menu-button"
        component="nav"
      >
        {locales.map((language) => (
          <LanguageMenuItem
            key={language}
            lang={language}
            onClick={handleSelectLanguage}
          />
        ))}
      </MenuList>
    );
  },
);

const useStyles = makeStyles(() => ({
  item: {
    paddingLeft: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: palette.paleGrey.main,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
    },
  },
  image: {
    width: 'calc(100% + 2px)',
    objectFit: 'contain',
  },
  itemText: {
    fontWeight: 400,
    margin: 0,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  isSelected: {
    textDecoration: 'underline',
    [theme.breakpoints.up('md')]: {
      textDecoration: 'none',
    },
  },
  icon: {
    width: 54,
    height: 32.4,
    borderRadius: 8,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

// Needed to forward refs for click away listener
LanguageMenu.displayName = 'LanguageMenu';

LanguageMenu.propTypes = {
  open: PropTypes.bool,
  onListKeyDown: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

LanguageMenu.defaultProps = {
  onListKeyDown: () => {
    // do nothing
  },
  open: false,
};

LanguageMenuItem.propTypes = {
  lang: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LanguageMenu;
