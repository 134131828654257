const navLinks = [
  { title: 'navigation.findJobs', link: '/' },
  { title: 'navigation.topCompanies', link: '/top_companies' },
  {
    title: 'navigation.forEmployers',
    link: 'https://nploy.net/business/for-employers',
  },
];

export default navLinks;
