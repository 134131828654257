import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Modal } from '@nploy/ui/web';
import slugify from 'utils/slugify';
import { IModalWithCloseOnBack } from './modal-with-close-on-back.interface';

export const ModalWithCloseOnBack: FC<
  PropsWithChildren<IModalWithCloseOnBack>
> = ({
  children,
  onClose,
  open,
  title,
  fixedWidth,
  titleComponent,
  addHash,
  ...props
}) => {
  const router = useRouter();

  const handleBackButtonClick = () => {
    onClose();
  };
  const handleClose = () => {
    onClose();
    if (open && addHash) {
      window.removeEventListener('hashchange', handleBackButtonClick);
      router.replace(router.asPath.split('#')[0]);
    }
  };

  useEffect(() => {
    if (open && addHash) {
      router.replace(
        `${router.asPath.split('#')[0]}${
          title ? `#${slugify(title)}` : '#modal'
        }`,
      );

      window.addEventListener('hashchange', handleBackButtonClick);
    } else {
      window.removeEventListener('hashchange', handleBackButtonClick);
    }

    return () =>
      window.removeEventListener('hashchange', handleBackButtonClick);
  }, [open, addHash]);

  return (
    <Modal
      open={open}
      title={title}
      fixedWidth={fixedWidth}
      titleComponent={titleComponent}
      onClose={handleClose}
      {...props}
    >
      {children}
    </Modal>
  );
};

ModalWithCloseOnBack.defaultProps = {
  fixedWidth: false,
  title: '',
  description: '',
  titleComponent: 'h1',
  addHash: true,
};
