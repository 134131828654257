import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const SCHEMA = process.env.NEXT_PUBLIC_MOBILE_APP_SCHEMA;
const APPSTORE_ID = process.env.NEXT_PUBLIC_MOBILE_APP_APPSTORE_ID;
const ANDROID_PACKAGE = process.env.NEXT_PUBLIC_MOBILE_APP_ANDROID_PACKAGE;

const APP_NAME = 'nPloy - Job Matching & Search';

const AppLinksMeta = ({ appLinkPath }) => {
  if (!SCHEMA) return null;

  return (
    <Head>
      <meta property="al:ios:url" content={`${SCHEMA}://${appLinkPath}`} />
      <meta property="al:ios:app_name" content={APP_NAME} />
      {APPSTORE_ID && (
        <meta property="al:ios:app_store_id" content={APPSTORE_ID} />
      )}

      <meta property="al:android:url" content={`${SCHEMA}://${appLinkPath}`} />
      <meta property="al:android:app_name" content={APP_NAME} />
      {ANDROID_PACKAGE && (
        <meta property="al:android:package" content={ANDROID_PACKAGE} />
      )}
    </Head>
  );
};

AppLinksMeta.propTypes = {
  appLinkPath: PropTypes.string.isRequired,
};

export default AppLinksMeta;
