import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { mutate } from 'swr';
import {
  axios,
  setAuthTokenData,
  deleteAuthToken,
  parseJWT,
} from '@nploy/shared';
import { logAnalyticsEvent, eventNames } from 'utils/analytics/event';
import { getErrorMessageFromResponseData } from 'utils/helpers/getErrorMessage';
import { clearLocalStorage } from 'utils/localStorage';
import { fetchData } from 'utils/swr/helpers/fetchData';
import { openFeedback } from 'store/reducers/feedbackReducer';
import {
  closeCreateAccountModal,
  closeLoginModal,
  openLoginModal,
} from 'store/reducers/uiReducer';

export const isRecruiter = (accessToken) => {
  const parsedToken = parseJWT(accessToken);
  const { role } = parsedToken.claims;
  return role === 'recruiter';
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const navigateToOnboardingIfUserNew = async () => {
    const { status } = await fetchData('settings/onboarding-status');
    if (!status) {
      await router.replace({
        pathname: '/onboarding',
        query: { redirectUrl: router.asPath.split('#')[0] },
      });
    }
  };

  // Sign up
  const signUp = async ({ full_name, email, password }) => {
    try {
      const res = await axios.post('auth/sign-up', {
        full_name,
        email,
        password,
      });

      dispatch(openFeedback({ type: 'success', message: res.data.message }));
      dispatch(closeCreateAccountModal());
      dispatch(openLoginModal());

      logAnalyticsEvent(eventNames.signUp, { category: 'email' });
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign up with LinkedIn
  const signUpWithLinkedIn = async (linkedin_code) => {
    try {
      const res = await axios.post('auth/social/sign-up-linkedin', {
        linkedin_code,
      });

      logAnalyticsEvent(eventNames.signUp, { category: 'linkedin' });

      onSignInSuccess(res, null, 'linkedin');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign up with Google
  const signUpWithGoogle = async (token) => {
    try {
      const res = await axios.post('auth/social/sign-up', {
        google_token: token,
      });

      logAnalyticsEvent(eventNames.signUp, { category: 'google' });

      // Sign up if token was sent
      if (res?.data?.access_token) {
        return onSignInSuccess(res, null, 'google');
      }

      // Show a success message with instructions if no token but a message
      if (res?.data?.message) {
        dispatch(closeCreateAccountModal());
        dispatch(openLoginModal());
        dispatch(openFeedback({ type: 'success', message: res.data.message }));
      }
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign in
  const signIn = async ({ email, password }, callback) => {
    try {
      const res = await axios.post('auth/sign-in', {
        email,
        password,
      });

      onSignInSuccess(res, callback, 'email');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, [
            'errors',
            'generalError',
          ]),
        }),
      );
    }
  };

  // Sign in LinkedIn
  const signInWithLinkedIn = async (linkedin_code, callback) => {
    try {
      const res = await axios.post('auth/social/sign-in-linkedin', {
        linkedin_code,
      });

      onSignInSuccess(res, callback, 'linkedin');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign in with Google
  const signInWithGoogle = async (token, callback) => {
    try {
      const res = await axios.post('auth/social/sign-in', {
        google_token: token,
      });

      onSignInSuccess(res, callback, 'google');
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  // Sign out
  const signOut = () => {
    deleteAuthToken();

    revalidateOnAuth();
  };

  const onSignInSuccess = (res, callback, provider) => {
    if (res?.data?.access_token) {
      closeAllModals();

      if (isRecruiter(res.data.access_token)) {
        return dispatch(
          openFeedback({
            type: 'error',
            message: 'recruiterLoginNotSupported',
          }),
        );
      }
      // Set token to cookies and headers Authorization
      setAuthTokenData(res.data);

      if (callback) callback();

      revalidateOnAuth();

      logAnalyticsEvent(eventNames.signIn, { category: provider });
      navigateToOnboardingIfUserNew();
      return dispatch(openFeedback({ type: 'success', message: 'signedIn' }));
    }
  };

  const forgotPassword = async (email, callback) => {
    try {
      await axios.post('auth/forgot-password-candidate', { email });
      if (callback) callback();

      dispatch(openFeedback({ type: 'success', message: 'passwordReset' }));
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  const resetPassword = async (
    { email, password, password_confirmation, token },
    callback,
  ) => {
    try {
      await axios.post('auth/forgot-password-change', {
        email,
        password,
        password_confirmation,
        token,
      });

      dispatch(openFeedback({ type: 'success', message: 'passwordChanged' }));

      if (callback) callback();
    } catch (error) {
      dispatch(
        openFeedback({
          type: 'error',
          message: getErrorMessageFromResponseData(error, ['errors']),
        }),
      );
    }
  };

  const closeAllModals = () => {
    dispatch(closeLoginModal());
    dispatch(closeCreateAccountModal());
  };

  return {
    signUp,
    signUpWithLinkedIn,
    signUpWithGoogle,
    signIn,
    signInWithLinkedIn,
    signInWithGoogle,
    signOut,
    forgotPassword,
    resetPassword,
  };
};

// Trigger mutates and clear storage
const revalidateOnAuth = () => {
  clearLocalStorage();
  triggetMutates();
};

const triggetMutates = () => {
  mutate('user', true);
  mutate('filters/general/get');
  mutate('settings/onboarding-status');
};
