import { AxiosRequestConfig } from 'axios';
import { axios } from '@nploy/shared';

export const fetchData = async <T>(
  url: string,
  params?: AxiosRequestConfig['params'],
) => {
  const res = await axios.get<T>(url, params && { params });
  return res.data;
};

// TODO: ask BE to fix all routes that use this to GET and use normal fetch data
export const fetchDataWithPostRequest = async <T>(
  url: string,
  params?: Record<string, any>,
) => {
  const res = await axios.post<T>(url, params || null);
  return res.data;
};
