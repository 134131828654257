import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { validateCVSync } from '@nploy/shared';
import {
  faCog,
  faFileUser,
  faSignOut,
  faWaveSine,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import theme from 'theme/theme';
import { firebaseCloudMessaging } from 'utils/helpers/webPush';
import useTranslate from 'utils/i18n/useTranslate';
import { useAuth } from 'utils/swr/hooks/useAuth';
import {
  openBuildCVModal,
  showNotPermitted,
  hideNotPermitted,
} from 'store/reducers/uiReducer';

const MoreMenuItem = ({ icon, text, onClick }) => {
  const { t } = useTranslate();
  const classes = useStyles();

  return (
    <MenuItem classes={{ root: classes.item }} onClick={onClick}>
      <ListItemIcon classes={{ root: classes.icon }}>
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.itemText }}>
        {t(`moreMenu.${text}`)}
      </ListItemText>
    </MenuItem>
  );
};

const MoreMenu = React.forwardRef(({ open, onListKeyDown, onClose }, ref) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { fullComplete: isCVCompleted, data: cv } = useSelector(({ cv }) => cv);
  const { isValid } = validateCVSync(cv);
  const { signOut } = useAuth();
  const handleCVClick = () => {
    onClose();
    if (isCVCompleted || isValid || router.pathname === '/onboarding') {
      router.push('/resume');
    } else {
      dispatch(openBuildCVModal());
    }
  };

  const handleActivityClick = () => {
    onClose();
    router.push('/activity');
  };

  const handleSettings = () => {
    onClose();
    router.push('/settings');
  };

  const handleLogout = async () => {
    try {
      dispatch(hideNotPermitted());
      await firebaseCloudMessaging.remove();
      onClose();
      signOut();
      await router.push('/');
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showNotPermitted());
    }
  };

  return (
    <MenuList
      ref={ref}
      autoFocusItem={open}
      onKeyDown={onListKeyDown}
      id="more-menu"
      aria-labelledby="more-menu-button"
      component="nav"
    >
      <MoreMenuItem icon={faFileUser} text="cv" onClick={handleCVClick} />

      <MoreMenuItem
        icon={faWaveSine}
        text="activity"
        onClick={handleActivityClick}
      />

      <MoreMenuItem icon={faCog} text="settings" onClick={handleSettings} />

      <MoreMenuItem icon={faSignOut} text="logout" onClick={handleLogout} />
    </MenuList>
  );
});

const useStyles = makeStyles(() => ({
  item: {
    paddingLeft: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: palette.paleGrey.main,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  itemText: {
    fontWeight: 400,
    margin: 0,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  icon: {
    minWidth: 30,
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

// Needed to forward refs for click away listener
MoreMenu.displayName = 'MoreMenu';

MoreMenu.propTypes = {
  open: PropTypes.bool,
  onListKeyDown: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

MoreMenu.defaultProps = {
  onListKeyDown: () => {
    // do nothing
  },
  open: false,
};

MoreMenuItem.propTypes = {
  icon: PropTypes.shape({}).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MoreMenu;
