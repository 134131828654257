import theme from 'theme/theme';

export const headerIconButtonStyles = {
  unreadContainer: {
    position: 'absolute',
    right: 3,
    top: 3,
    height: 22,
    width: 22,
    backgroundColor: theme.palette.error.main,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
  },
  unreadText: {
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  iconStyle: {
    height: '1em',
  },
  iconButton: {
    p: '12px',
  },
};
