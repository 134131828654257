import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useCreateAccountFormStyles = makeStyles(() => ({
  disableOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    opacity: 0.7,
    zIndex: 1,
  },
}));
