import palette from 'theme/palette';

export const layoutStyles = {
  pageContainer: {
    display: 'block',
    minWidth: 350,
    overflowX: 'auto',
    minHeight: '100vh',
    py: 0,
    px: 0,
    backgroundColor: palette.pageBackgroundGrey.main,
  },
  main: {
    position: 'relative',
    overflowX: 'hidden',
    minHeight: `calc(100vh - 80px)`,
  },
};
