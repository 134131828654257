import * as Sentry from '@sentry/nextjs';
import { PolicyEnum } from 'constants/privacyPolicy';
import { fetchBuildData } from 'utils/buildHelpers/buildApiRequests';
import { fetchDataWithPostRequest } from 'utils/swr/helpers/fetchData';
import {
  FetchPolicyType,
  SavePolicyType,
  TermsConditionsAndPrivacyPolicy,
} from '../types';

export const checkPolicyStatus = async (
  data: FetchPolicyType,
): Promise<{ status: boolean }> => {
  try {
    if (data) {
      const status = await fetchDataWithPostRequest(
        'settings/check-policy',
        data,
      );
      // must be exactly false, null or undefined etc. will be wrong status in this case
      if (status === false) {
        return { status: false };
      }
      return { status: true };
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getTermsConditionsAndPrivacyPolicy = async (
  locale = 'en',
  typePolicy: PolicyEnum,
): Promise<TermsConditionsAndPrivacyPolicy> => {
  try {
    return fetchBuildData(`get-policy/${locale}/type/${typePolicy}`);
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const savePolicy = async (
  policyData: FetchPolicyType,
): Promise<SavePolicyType> => {
  try {
    const { data } = await fetchDataWithPostRequest<{ data: SavePolicyType }>(
      'settings/save-policy',
      policyData,
    );
    if (data) {
      return data;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};
